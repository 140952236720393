import React from "react";
import ProductItems, { IProductItemsProps } from "./ProductItems";
import { useTranslation } from "react-i18next";

export interface IProductProps {
  app: IProductItemsProps[];
}

const Product = ({ app }: IProductProps) => {
  const { t, i18n } = useTranslation();
  const title1 =
    i18n.language === "en" ? t("header.featured") : t("header.products");
  const title2 =
    i18n.language === "en" ? t("header.products") : t("header.featured");
  return (
    <section className="mt-14 snap-m-14">
      <div className="max-w-5.5xl mx-auto px-2 xl:px-6 pb-6">
        <h1 className="text-3xl font-semibold border-b-2 border-primary pb-3 mb-6">
          <span className="text-primary firstLetterUppercase">{title1}</span>{" "}
          {title2}
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {app.map(({ image, name, desc, url }, index) => {
            return (
              <ProductItems
                key={index}
                image={image}
                name={name}
                desc={desc}
                url={url}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Product;
