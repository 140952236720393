import React from "react";

export interface ISloganProps {
  image: string;
  name: string;
  content: string;
}

const Slogan = ({ image, name, content }: ISloganProps) => {
  return (
    <section className="mt-14 snap-m-14 bg-slogan bg-opacity-20">
      <div className="max-w-5.5xl mx-auto grid grid-cols-1 sm:grid-cols-2 px-2 xl:px-6 ">
        <div className="">
          <img
            src={`${process.env.REACT_APP_API_HOST}${image}`}
            alt="slogan_img"
            className="w-full"
          />
        </div>
        <div className="flex font-semibold justify-center items-center text-center">
          <div>
            <p className="text-3xl pb-3 mb-4 leading-10">{name}</p>
            <p className="sm:max-w-2xl sm:px-6 md:px-10 mx-auto text-xl leading-10">
              {content}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slogan;
