import axios from "axios";
import i18next from "./i18n";

const instance = axios.create({
  headers: {
    Locale: i18next.language,
  },
});
// instance.interceptors.request.use(() => ({
//   headers: {
//     // language: 1234,
//   },
// }));
export default instance;
