import React from "react";
import Scrollspy from "react-scrollspy";
import logo from "../assets/image/logo.png";
import flag_vi from "../assets/image/flag_vn.png";
import flag_en from "../assets/image/flag_en.png";
import flag_ja from "../assets/image/flag_ja.png";
import api from "../axios";
import NavbarMobile from "./NavbarMobile/Sidebar";
import { useTranslation } from "react-i18next";
import useActiveLocale from "../hooks/useActiveLocale";
// import { locales } from "../constants/locale";

const locales = [
  {
    code: "en",
    flag: flag_en,
  },
  {
    code: "vi",
    flag: flag_vi,
  },
  {
    code: "ja",
    flag: flag_ja,
  },
];

const Header = ({ getData }: { getData: () => void }) => {
  const { t, i18n } = useTranslation();
  const activeLocale = useActiveLocale();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    api.defaults.headers["Locale"] = lang;
    window.history.replaceState(null, "", "/" + lang);

    getData();
  };

  const renderFlag = () => {
    return locales.map((locale) => {
      return (
        <img
          key={locale.code}
          className={`cursor-pointer border-2 p-0.5 border-transparent ${
            locale.code === activeLocale.code ? "border-primary" : null
          }`}
          alt={`flag_${locale.code}`}
          src={locale.flag}
          onClick={() => changeLanguage(locale.code)}
        />
      );
    });
  };

  return (
    <section className="sticky top-0 z-20 border-b shadow-heading bg-white">
      <header className="flex justify-between items-center py-2 px-4 xl:px-6 max-w-5.5xl mx-auto">
        <div className="w-20 lg:w-44">
          <img alt="logo" src={logo} />
        </div>
        <nav className="font-semibold hidden md:flex text-xs lg:text-sm">
          <Scrollspy
            items={["service", "portfolio", "customer", "support"]}
            currentClassName="text-primary"
            className="space-x-5"
          >
            <a href="#service" className="hover:text-primary uppercase">
              {t("menu.services")}
            </a>
            <a href="#portfolio" className="hover:text-primary uppercase">
              {t("menu.portfolio")}
            </a>
            <a href="#customer" className="hover:text-primary uppercase">
              {t("menu.customers")}
            </a>
            <a href="#support" className="hover:text-primary uppercase">
              {t("menu.getQuotation")}
            </a>
          </Scrollspy>
          {/* <NavbarMobile /> */}
        </nav>
        <div className="flex items-center gap-2">
          <a
            href="#contact"
            className="flex items-center justify-center text-primary border border-primary md:text-lg rounded-lg font-semibold w-28 md:w-32 md:h-11.5"
          >
            {t("menu.contactUs")}
          </a>
          {renderFlag()}
        </div>
        <NavbarMobile />
      </header>
    </section>
  );
};
export default Header;
