import React from "react";
import { useTranslation } from "react-i18next";

export interface ITechnicalProps {
  content: string;
  data: { image: string; name: string; url: string }[];
}

const Technical = ({ content, data }: ITechnicalProps) => {
  const { t } = useTranslation();
  return (
    <section id="" className="mt-14 snap-m-14">
      <div className="max-w-5.5xl mx-auto px-2 xl:px-6 pb-6">
        <h1 className="text-3xl font-semibold text-center pb-3 mb-6">
          {t("header.industries")}
        </h1>
        <div
          className="sm:mx-32 md:mx-40 lg:mx-80 text-center text-xl"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="mt-16 px-10 md:px-28 flex flex-wrap justify-center">
          {data.map(({ image, url, name }, index) => {
            return (
              <div
                className="px-6 pb-6 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6"
                key={index}
              >
                <a href={url} target="_blank" rel="noreferrer">
                  <img
                    src={`${process.env.REACT_APP_API_HOST}${image}`}
                    alt={name}
                    className="w-full"
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Technical;
