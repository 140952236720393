export type localesType = {
  code: string;
  locale: string[];
  default: boolean;
};
export const locales = [
  {
    code: "en",
    locale: ["en", "en-US"],
    default: true,
  },
  {
    code: "vi",
    locale: ["vi", "vi-VN"],
    default: false,
  },
  {
    code: "ja",
    locale: ["ja", "ja-JP"],
    default: false,
  },
];
