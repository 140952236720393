import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

export interface ICustomerListProps {
  name: string;
  image: string;
  url: string;
}
export interface ICustomerProps {
  customer: ICustomerListProps[];
}

const Customer = ({ customer }: ICustomerProps) => {
  const { t } = useTranslation();

  const settings = {
    autoplay: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      id="customer"
      className="mt-14 snap-m-16 bg-customer-background bg-no-repeat bg-cover"
    >
      <div className="max-w-5.5xl mx-auto px-2 xl:px-6">
        <div className="pt-6 pb-8">
          <h1 className="text-3xl font-semibold pb-3 mb-6 text-center leading-10 text-white">
            {t("header.ourCustomers")}
          </h1>
          <div className="pb-0 pt-1 md:hidden">
            <Slider {...settings}>
              {customer.map(({ image, name, url }, index) => {
                return (
                  <div className="px-6 pb-6 w-full" key={index}>
                    <a href={url} target="_blank" title={name} rel="noreferrer">
                      <img
                        src={`${process.env.REACT_APP_API_HOST}${image}`}
                        alt=""
                        title={name}
                        className="w-full mb-2"
                      />
                    </a>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="px-12 pb-0 pt-1 hidden md:flex flex-wrap justify-center">
            {customer.map(({ image, name, url }, index) => {
              return (
                <div className="px-6 pb-6 w-full sm:w-1/2 lg:w-1/5" key={index}>
                  <a href={url} target="_blank" title={name} rel="noreferrer">
                    <img
                      src={`${process.env.REACT_APP_API_HOST}${image}`}
                      alt=""
                      title={name}
                      className="w-full mb-2"
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Customer;
