import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import useActiveLocale from "../hooks/useActiveLocale";

export interface ISupportProps {
  content: string;
  company_name: string;
  company_phone: string;
  company_email: string;
  company_skype: string;
}

interface ISubmitDataType {
  name: string;
  skype: string;
  email: string;
  note: string;
  phone: string;
}

const Support = ({
  content,
  company_name,
  company_phone,
  company_email,
  company_skype,
}: ISupportProps) => {
  const { t, i18n } = useTranslation();
  const activeLocale = useActiveLocale();

  const formRef = useRef<FormikProps<ISubmitDataType>>(null);

  const [isShowModal, setIsShowModal] = useState(false);

  const isEN = activeLocale.code === "en";

  const BaseSchema = Yup.object().shape({
    name: Yup.string().max(32, t("form.tooLong")).required(t("form.required")),
    email: Yup.string().required(t("form.required")).email("form.invalidEmail"),
    note: Yup.string().min(20, t("form.tooShort")).required(t("form.required")),
  });

  const ContactSchema = isEN
    ? BaseSchema.shape({
        skype: Yup.string()
          .min(2, t("form.tooShort"))
          .max(100, t("form.tooLong"))
          .required(t("form.required")),
      })
    : BaseSchema.shape({
        phone: Yup.string()
          .min(10, t("form.tooShort"))
          .max(15, t("form.tooLong"))
          .required(t("form.phoneRequired")),
      });

  const handleLanguageChanged = useCallback(() => {
    formRef.current?.resetForm();
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);
    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [handleLanguageChanged, i18n]);

  const onSubmitForm = (
    value: ISubmitDataType,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: any
  ) => {
    axios
      .post(`${process.env.REACT_APP_API_HOST}/contact`, value)
      .then(() => {
        resetForm();
        setSubmitting(false);
        showModal(true);
      })
      .catch(function (error) {
        console.log(error);
        setSubmitting(false);
      });
  };

  const showModal = (isShow: boolean) => {
    setIsShowModal(isShow);
    document.body.style.overflow = isShow ? "hidden" : "auto";
  };
  return (
    <section id="support" className="mt-14 snap-m-14 bg-gray-104 bg-opacity-10">
      <div className="max-w-5.5xl mx-auto pt-8 px-2 xl:px-6 pb-11">
        <div className="grid grid-cols-12">
          <div className="flex bbb px-6 md:w-96 mx-auto leading-7 col-span-12 md:col-span-6">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>

          <div className="flex col-span-12 md:col-span-6">
            <Formik
              initialValues={{
                name: "",
                skype: "",
                email: "",
                note: "",
                phone: "",
              }}
              validationSchema={ContactSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmitForm(values, setSubmitting, resetForm);
              }}
              innerRef={formRef}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="mx-auto w-full px-6 xs:w-11/12 sm:w-5/6 pt-7 md:pt-0 md:px-0 md:w-4/5 md:ml-0"
                >
                  <div className="mb-3 space-y-3">
                    <p className="font-bold">
                      {t("form.fullName")}{" "}
                      <span className="text-primary">*</span>
                    </p>
                    <input
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      name="name"
                      placeholder={company_name}
                      className="w-full px-3 py-3 placeholder-gray-105 rounded-lg border border-gray-102 focus:ring-primary focus:border-primary"
                    />
                    <span className="text-sm">
                      {errors.name && touched.name && errors.name}
                    </span>
                  </div>
                  <div className="mb-3 space-y-3">
                    <p className="font-bold">
                      Email <span className="text-primary">*</span>
                    </p>
                    <input
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name="email"
                      placeholder={company_email}
                      className="w-full px-3 py-3 placeholder-gray-105 rounded-lg border border-gray-102 focus:ring-primary focus:border-primary"
                    />
                    <span className="text-sm">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  {isEN ? (
                    <div className="mb-3 space-y-3">
                      <p className="font-bold">
                        Skype <span className="text-primary">*</span>
                      </p>
                      <input
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.skype}
                        name="skype"
                        placeholder={company_skype}
                        className="w-full px-3 py-3 placeholder-gray-105 rounded-lg border border-gray-102 focus:ring-primary focus:border-primary"
                      />
                      <span className="text-sm">
                        {errors.skype && touched.skype && errors.skype}
                      </span>
                    </div>
                  ) : (
                    <div className="mb-3 space-y-3">
                      <p className="font-bold">
                        {t("form.phone")}{" "}
                        <span className="text-primary">*</span>
                      </p>
                      <input
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        name="phone"
                        placeholder={company_phone}
                        className="w-full px-3 py-3 placeholder-gray-105 rounded-lg border border-gray-102 focus:ring-primary focus:border-primary"
                      />
                      <span className="text-sm">
                        {errors.phone && touched.phone && errors.phone}
                      </span>
                    </div>
                  )}
                  <div className="mb-11 space-y-3">
                    <p className="font-bold">
                      {t("form.message")}{" "}
                      <span className="text-primary">*</span>
                    </p>
                    <textarea
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.note}
                      name="note"
                      placeholder={t("form.messagePlaceholder")}
                      className="h-32 w-full px-3 py-3 placeholder-gray-105 rounded-lg border border-gray-102 focus:ring-primary focus:border-primary"
                    />
                    <span className="text-sm">
                      {errors.note && touched.note && errors.note}
                    </span>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="shadow-button text-white font-bold uppercase w-3/5 h-14 rounded-lg bg-primary bg-opacity-80 hover:bg-primary hover:bg-opacity-90 focus:outline-none focus:bg-opacity-90 focus:ring-2 focus:ring-primary focus:ring-opacity-50"
                    >
                      <div className="flex justify-center items-center">
                        <div className={`${isSubmitting ? "" : "hidden"}`}>
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 mr-2 border-gray-900"></div>
                        </div>
                        <span>{t("form.submit")}</span>
                      </div>
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div
        className={`${
          isShowModal ? "" : "hidden"
        } min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover`}
        id="modal-id"
      >
        <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
        <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
          <div className="">
            <div className="text-center p-5 flex-auto justify-center">
              <svg
                className="w-15 h-15 -m-1 flex items-center text-red-500 mx-auto"
                viewBox="0 0 50 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 0.03125C11.6211 0.03125 0.78125 10.9688 0.78125 24.25C0.78125 37.6289 11.6211 48.4688 25 48.4688C38.2812 48.4688 49.2188 37.6289 49.2188 24.25C49.2188 10.9688 38.2812 0.03125 25 0.03125ZM25 4.71875C35.7422 4.71875 44.5312 13.5078 44.5312 24.25C44.5312 35.0898 35.7422 43.7812 25 43.7812C14.1602 43.7812 5.46875 35.0898 5.46875 24.25C5.46875 13.5078 14.1602 4.71875 25 4.71875ZM38.6719 17.5117L36.4258 15.2656C36.0352 14.7773 35.2539 14.7773 34.7656 15.2656L20.9961 28.9375L15.1367 23.0781C14.6484 22.5898 13.9648 22.5898 13.4766 23.0781L11.2305 25.2266C10.8398 25.7148 10.8398 26.4961 11.2305 26.8867L20.1172 35.8711C20.6055 36.3594 21.2891 36.3594 21.7773 35.8711L38.6719 19.1719C39.0625 18.6836 39.0625 17.9023 38.6719 17.5117Z"
                  fill="#F4685B"
                />
              </svg>

              <p className="text-xl font-bold pt-4 ">{t("form.thankYou")}</p>
              <p className="text-sm font-bold pt-2">
                {t("form.thankYouDetail")}
              </p>
            </div>
            <div className="p-3 text-center space-x-4 md:block">
              <button
                onClick={() => showModal(false)}
                className="w-52 mb-2 font-bold md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600"
              >
                {t("form.ok")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Support;
