import React from "react";

export interface IProductItemsProps {
  name: string;
  image: string;
  desc: string;
  url: string;
}

const ProductItems = ({ name, image, desc, url }: IProductItemsProps) => {
  return (
    <div>
      <a href={url} target="_blank" rel="noreferrer">
        <p className="text-xl uppercase font-semibold mb-8">{name}</p>
        <img
          src={`${process.env.REACT_APP_API_HOST}${image}`}
          alt={name}
          className="w-full mb-2"
        />
      </a>
      <div className="py-6">
        <p className="leading-7">{desc}</p>
      </div>
    </div>
  );
};

export default ProductItems;
