import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { locales, localesType } from "../constants/locale";

const useActiveLocale = () => {
  const [data, setData] = useState<localesType>(locales[0]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = i18n.language;
    //tim language theo code va locale
    const checkLang = locales.filter((item) => {
      return lang === item.code || item.locale.indexOf(lang) >= 0;
    });
    //neu tim thay thi return
    if (checkLang.length) {
      setData(checkLang[0]);
      return;
    }
    //neu khong thay thi lay local default
    const defaultLang = locales.filter((item) => {
      return item.default;
    });
    setData(defaultLang[0]);
    return;
  }, [i18n.language]);

  return data;
};

export default useActiveLocale;
