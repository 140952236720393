import React, { useEffect, useState } from "react";
// import axios from "axios";
import { Helmet } from "react-helmet";
import contactImgSkype from "./assets/image/skype.svg";
import contactImgZalo from "./assets/image/artboard.svg";
import Header from "./components/Header";
import Banner, { IBannerProps } from "./components/Banner";
import Service, { IServiceProps } from "./components/Service";
import Technical, { ITechnicalProps } from "./components/Technical";
import Portfolio, { IPortfolioProps } from "./components/Portfolio";
import Product from "./components/Product";
import { IProductItemsProps } from "./components/ProductItems";
import Customer, { ICustomerListProps } from "./components/Customer";
import Feedback, { IFeedbackListProps } from "./components/Feedback";
import Slogan, { ISloganProps } from "./components/Slogan";
// import Member from "./components/Member";
import Contact from "./components/Contact";
import Support, { ISupportProps } from "./components/Support";
import Footer, {
  IFooterProductListProps,
  IActionGuidelineProps,
} from "./components/Footer";
import api from "./axios";
import { useTranslation } from "react-i18next";
import useActiveLocale from "./hooks/useActiveLocale";
import { locales } from "./constants/locale";

interface ISettingProps {
  company_address: string;
  google_map: string;
  company_phone: string;
  telegram: string;
  company_email: string;
  skype: string;
  name: string;
  meta_title: string;
  meta_desc: string;
  meta_keyword: string;
}

interface IAppData {
  banner: IBannerProps;
  slogan: ISloganProps;
  service: IServiceProps;
  tech: ITechnicalProps;
  portfolio: IPortfolioProps;
  app: IProductItemsProps[];
  customer: ICustomerListProps[];
  customer_feedback: IFeedbackListProps[];
  action_guideline: IActionGuidelineProps;
  setting: ISettingProps;
  handle_request: ISupportProps;
  footer_product: IFooterProductListProps[];
  quality_commitment: { desc: string };
}

const localeList = locales.map((locale) => locale.code);

function App() {
  const [data, setData] = useState<null | IAppData>(null);
  const { i18n } = useTranslation();
  const activeLocale = useActiveLocale();

  useEffect(() => {
    const query: string = window.location.pathname;
    const lang = query.split("/")[1];
    if (localeList.includes(lang)) {
      i18n.changeLanguage(lang);
      api.defaults.headers["Locale"] = lang;
    }
    getData();
  }, [i18n]);

  const getData = () => {
    api
      .get(`${process.env.REACT_APP_API_HOST}/setting`)
      .then((res) => setData(res.data));
  };

  if (data === null) return null;

  const contactView =
    activeLocale.code === "en" ? (
      <a title="skype" href={`skype:${data.setting.skype}?chat`}>
        <img
          alt="skype"
          src={contactImgSkype}
          className="fixed bottom-8 right-12 w-12 h-12"
        />
      </a>
    ) : (
      <a
        title="zalo"
        target="_blank"
        rel="noreferrer"
        href={`https://zalo.me/${data.setting.company_phone.replaceAll(
          " ",
          ""
        )}`}
      >
        <img
          alt="zalo"
          src={contactImgZalo}
          className="fixed bottom-8 right-12 w-12 h-12"
        />
      </a>
    );

  return (
    <div className="font-gotham">
      <Helmet>
        <title>{data.setting.name}</title>
        <meta name="keywords" content={data.setting.meta_keyword} />
        <meta name="description" content={data.setting.meta_desc} />
        <meta
          property="og:url"
          itemProp="url"
          content={process.env.REACT_APP_HOST}
        />
        <meta property="og:title" content={data.setting.meta_title} />
        <meta property="og:description" content={data.setting.meta_desc} />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_API_HOST}${data.banner.image}`}
        />
        <link rel="canonical" href={process.env.REACT_APP_HOST} />
      </Helmet>
      {/* {header} */}
      <Header getData={getData} />
      <main>
        {/* {banner} */}
        <Banner
          image={data.banner.image}
          name={data.banner.name}
          content={data.banner.content}
        />

        {/* {Feedback} */}
        <Feedback customer_feedback={data.customer_feedback} />

        {/* {dịch vụ} */}
        <Service content={data.service.content} data={data.service.data} />

        {/* {Công nghệ} */}
        <Technical content={data.tech.content} data={data.tech.data} />

        {/* {Portfolio} */}
        <Portfolio types={data.portfolio.types} data={data.portfolio.data} />

        {/* {Product} */}
        <Product app={data.app} />

        {/* {Customer} */}
        <Customer customer={data.customer} />

        {/* {Slogan} */}
        <Slogan
          image={data.slogan.image}
          name={data.slogan.name}
          content={data.slogan.content}
        />

        {/* {Member} */}
        {/* <Member /> */}

        {/* {Contact} */}
        <Contact
          company_address={data.setting.company_address}
          google_map={data.setting.google_map}
          company_phone={data.setting.company_phone}
          telegram={data.setting.telegram}
          company_email={data.setting.company_email}
          skype={data.setting.skype}
        />

        {/* {Support} */}
        <Support
          content={data.handle_request.content}
          company_name={data.setting.name}
          company_skype={data.setting.skype}
          company_phone={data.setting.company_phone}
          company_email={data.setting.company_email}
        />

        {/* {Footer} */}
        <Footer
          descCommitment={data.quality_commitment.desc}
          desc={data.action_guideline.desc}
          company_address={data.setting.company_address}
          company_phone={data.setting.company_phone}
          telegram={data.setting.telegram}
          company_email={data.setting.company_email}
          skype={data.setting.skype}
          footer_product={data.footer_product}
        />
      </main>
      {contactView}
    </div>
  );
}

export default App;
