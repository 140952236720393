import React from "react";
import { useTranslation } from "react-i18next";
import ServiceItems from "./ServiceItems";

export interface IServiceProps {
  content: string;
  data: { image: string; name: string; desc: string }[];
}

const Service = ({ content, data }: IServiceProps) => {
  const { t } = useTranslation();
  return (
    <section id="service" className="mt-14 snap-m-16">
      <div className="max-w-5.5xl mx-auto px-2 xl:px-6 pb-6">
        <h1 className="text-3xl font-semibold border-b-2 border-primary pb-3 mb-6">
          {t("header.services")}
        </h1>
        <div className="text-xl mb-5">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {data.map(({ image, name, desc }, index) => (
            <ServiceItems key={index} image={image} name={name} desc={desc} />
          ))}
        </div>
      </div>
    </section>
  );
};
export default Service;
