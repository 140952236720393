import React from "react";
import useActiveLocale from "../hooks/useActiveLocale";

export interface IPortfolioItemsProps {
  id: string;
  name: string;
  subtitle: string;
  image: string;
  date: string;
  url: string;
  category_id: string;
}

const PortfolioItems = ({
  name,
  subtitle,
  image,
  date,
  url,
}: IPortfolioItemsProps) => {
  const activeLocale = useActiveLocale();

  const d = new Date(date);
  const ye = new Intl.DateTimeFormat(activeLocale.code, {
    year: "numeric",
  }).format(d);
  const mo = new Intl.DateTimeFormat(activeLocale.code, {
    month: "long",
  }).format(d);

  const dateFormated = `${mo} ${ye}`;

  return (
    <div>
      <a href={url} target="_blank" rel="noreferrer">
        <img
          src={`${process.env.REACT_APP_API_HOST}${image}`}
          alt={name}
          className="w-full mb-2"
        />
      </a>
      <div className="p-3 bg-gray-103 bg-opacity-40">
        <p className="text-xs uppercase font-semibold pb-1">{subtitle}</p>
        <div className="mb-3">
          <a href={url} target="_blank" rel="noreferrer">
            <p
              title={name}
              className="text-lg uppercase font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap"
            >
              {name}
            </p>
          </a>
          <div className="mt-2 h-0 border-b-0.8 w-4/12 border-primary "></div>
        </div>
        <p className="text-gray-102 text-xs">{dateFormated}</p>
      </div>
    </div>
  );
};

export default PortfolioItems;
