import React, { useState } from "react";
import PortfolioItems, { IPortfolioItemsProps } from "./PortfolioItems";
import { useTranslation } from "react-i18next";

export interface IPortfolioProps {
  types: { name: string; id: string }[];
  data: IPortfolioItemsProps[];
}

const Portfolio = ({ types, data }: IPortfolioProps) => {
  const [active, setActive] = useState<string>("0");
  const { t } = useTranslation();

  const handleClick = (id: string) => {
    setActive(id);
  };

  const dataFilter = () => {
    return data
      .filter(({ category_id }) =>
        active === "0" ? true : active === category_id
      )
      .map(({ id, name, subtitle, image, date, url, category_id }) => {
        return (
          <PortfolioItems
            id={id}
            key={id}
            image={image}
            subtitle={subtitle}
            name={name}
            date={date}
            url={url}
            category_id={category_id}
          />
        );
      });
  };

  return (
    <section id="portfolio" className="mt-14 snap-m-16">
      <div className="max-w-5.5xl mx-auto px-2 xl:px-6 pb-6">
        <h1 className="text-3xl font-semibold border-b-2 border-primary pb-3 mb-6">
          {t("header.portfolio")}
        </h1>
        <div className="inline-flex space-x-4 mt-2 mb-8">
          <div
            onClick={() => handleClick("0")}
            className={`uppercase text-sm font-semibold hover:text-primary cursor-pointer ${
              active === "0" ? "text-primary" : null
            }`}
          >
            {t("common.allProjects")}
          </div>
          {types.map(({ name, id }) => {
            return (
              <div
                key={id}
                onClick={() => handleClick(id)}
                className={`uppercase text-sm font-semibold hover:text-primary cursor-pointer ${
                  active === id ? "text-primary" : null
                }`}
              >
                {name}
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {dataFilter()}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
