import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

export interface IFeedbackListProps {
  image: string;
}
export interface IFeedbackProps {
  customer_feedback: IFeedbackListProps[];
}

const Feedback = ({ customer_feedback }: IFeedbackProps) => {
  const { t, i18n } = useTranslation();
  const title1 =
    i18n.language === "en" || i18n.language === "ja" ? t("header.customer") : t("header.reviews");
  const title2 =
    i18n.language === "en" || i18n.language === "ja" ? t("header.reviews") : t("header.customer");

  const settings = {
    autoplay: true,
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="mt-14 snap-m-14">
      <div className="max-w-5.5xl mx-auto px-2 pt-6 xl:px-6 pb-8 font-semibold text-center">
        <h1 className="text-3xl pb-3 mb-6 leading-10 uppercase">
          {title1} <span className="text-primary">{title2}</span>
        </h1>
        <div>
          <Slider {...settings}>
            {customer_feedback.map(({ image }, index) => {
              return (
                <div key={index}>
                  <img
                    src={`${process.env.REACT_APP_API_HOST}${image}`}
                    alt=""
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
