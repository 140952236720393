import React from "react";

export interface IServiceItemsProps {
  image: string;
  name: string;
  desc: string;
}

const ServiceItems = ({ image, name, desc }: IServiceItemsProps) => {
  return (
    <div className="text-center bg-gray-101 bg-opacity-10 rounded-lg py-5 px-3">
      <img
        src={`${process.env.REACT_APP_API_HOST}${image}`}
        alt=""
        className="w-5/6 mx-auto"
      />
      <div className="h-16 flex justify-center items-center my-6 px-4 overflow-hidden">
        <p className="text-1.375 font-semibold">{name}</p>
      </div>
      <div className="h-24 flex justify-center mb-4 overflow-hidden">
        <p>{desc}</p>
      </div>
    </div>
  );
};
export default ServiceItems;
