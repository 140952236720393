import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const { t } = useTranslation();
  const [isShowNave, setIsShowNave] = useState(false);
  return (
    <>
      <div
        className={`w-3 h-3 md:hidden`}
        onClick={() => setIsShowNave(!isShowNave)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-full w-full"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </div>
      <section
        className={`z-30 fixed top-0 right-0 h-screen md:hidden transition duration-500 ease-in-out transform border-l shadow-md ${
          isShowNave ? "" : "translate-x-full"
        }`}
      >
        <div className="w-screen max-w-xs bg-white h-full overflow-y-auto py-4 px-4">
          <div className="flex justify-end">
            <div
              className={`w-3 h-3 md:hidden`}
              onClick={() => setIsShowNave(!isShowNave)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-full w-full"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <Scrollspy
            items={["service", "portfolio", "customer", "support"]}
            currentClassName="text-primary"
            className="flex flex-col justify-between items-center space-y-3 text-sm"
          >
            <a href="#service" className="hover:text-primary uppercase">
              {t("menu.services")}
            </a>
            <a href="#portfolio" className="hover:text-primary uppercase">
              {t("menu.portfolio")}
            </a>
            <a href="#customer" className="hover:text-primary uppercase">
              {t("menu.customers")}
            </a>
            <a href="#support" className="hover:text-primary uppercase">
              {t("menu.getQuotation")}
            </a>
          </Scrollspy>
        </div>
      </section>
    </>
  );
};
export default Sidebar;
