import React from "react";

export interface IBannerProps {
  image: string;
  name: string;
  content: string;
}

const Banner = ({ image, name, content }: IBannerProps) => {
  return (
    <section id="" className="pt-1 snap-m-14">
      <div className="max-w-5.5xl mx-auto px-2 xl:px-6 grid grid-cols-1 md:grid-cols-2 gap-5 pb-7">
        <div className="flex flex-col justify-center">
          <h1 className="text-3xl leading-4.375 tracking-wide font-semibold md:text-5xl md:leading-4.375 uppercase mb-12">
            {name}
          </h1>
          <div>
            <div className="flex mb-4 aaa">
              <p
                className="text-xl leading-3.125 md:text-2xl md:leading-3.125 flex-1"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
        <div>
          <img
            src={`${process.env.REACT_APP_API_HOST}${image}`}
            alt="ava_banner"
            width="546"
            height="544"
            className="w-full rounded-tr-111 rounded-bl-111 shadow-heading"
          />
        </div>
      </div>
    </section>
  );
};
export default Banner;
